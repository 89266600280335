#gap-between-box {
  text-align: center;
  padding: 0px;
  gap: 8px;

}

#gap-between-box {
  font-size: 38px;
  padding: 0;
  padding-left: 0.5rem;
  padding-right: 2.4rem;
  color: white;
  cursor: default;

  min-height: 60px;
}

/* box styling */

#single-gap-between-box {
  font-size: 38px;
  padding: 0;
  padding-left: 0.5rem;
  padding-right: 2.4rem;
  cursor: default;
  min-height: 65px;
  border: 2px solid grey;
}

#gap-between-box-last {
  font-size: 38px;
  padding: 0;
  height: 58px;
  padding-left: 0.5rem;
  padding-right: 2.4rem;
  min-height: 65px;

  border: 2px solid grey;
}

/* box styling complete */

/* validation error on not word list found and not enough letters */
.validation-error {
  margin-top: 1rem;
  background-color: rgb(227, 227, 221);
  color: black;
  font-size: 1.3rem;
  max-width: 20%;
  border-radius: 10px;
  padding: 16px;
  font-weight: 500;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

/* green box when char is on correct position */

/* flipper css */
.flipBox {
  animation: flip 550ms ease forwards;
}



@keyframes flip {
  0% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

/* Yes and no button css */

/* 4 */
.btn4 {
  position: relative;
  color: white;
  z-index: 2;
  padding: 0;
  border-radius: 5%;
  width: 170px;
  height: 60px;
  font-size: 1.3rem;

}
.btn4:hover {
  border: none;
}
.btn4:before,
.btn4:after {
  position: absolute;
  content: "";
  width: 0%;
  height: 0%;
  border: 2px solid;
  z-index: -1;
  transition: all 0.3s ease;
}
.btn4:before {
  top: 0;
  left: 0;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: white;
  border-left-color: white;
}
.btn4:after {
  bottom: 0;
  right: 0;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: white;;
  border-right-color: white;;
}
.btn4:hover:before,
.btn4:hover:after {
  border-color: white;;
  height: 100%;
  width: 100%;
}

.customBtn {
  width: 130px;
  height: 40px;
  padding: 10px 25px;
  border: 2px solid #000;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
}


.yesAndNo {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.customBtn {
  margin: 20px;
  outline: none;
}

/*  game over text styling */

.gameOver {
  font-family: sans-serif;
  font-size: 5rem;
  text-align: center;
}

/* play game */

.playGame {
  font-family: 'Game Over', sans-serif;
  font-size: 2.5rem;
  text-align: center;
}

/* actual word */
.actualWordOnGameOver{
  font-family: 'Game Over', sans-serif;
  font-size: 2rem;
  text-align: center;

}

/* refresh page */
.refreshPage{
  color: white;
  text-decoration: underline;

}

.refreshPage:hover {
  color: white;
  text-decoration: none;


}

.section {
  padding-top: 2rem;
}


@media (max-width:64rem) {
  .base {
    width: 0px;

  }

  .section {
    padding-top: 2rem;
  }


  #gap-between-box {
    text-align: center;
    padding: 0px;
    gap: 8px;
    margin: -21px;

  }

  #gap-between-box {
    font-size: 38px;
    padding: 0;
    padding-left: 0.5rem;
    padding-right: 2.4rem;
    color: white;
    cursor: default;
    margin: -21px;
    min-height: 60px;
  }
}

