#project-name{
   margin-right: auto;
   margin-left: auto;
   font-size: 2rem;
}

:host >>> .customDialog {
   background-color: red;
   color: green;
}

.ui-dialog.ui-widget-content {
   background-image: none;
   background-color: red;
}

@media (max-width:64rem) {
   #project-name{
      text-align: center;
   }
}