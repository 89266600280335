.guide-list {
   list-style: none;
   padding: 0;
   margin: 0;
 }
 
 .guide-list li {
   padding-left: 1em; 
   text-indent: -.7em;
 }
 
 .guide-list li::before {
   content: "• ";
   color: white; /* or whatever color you prefer */
 }