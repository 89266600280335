span {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.base {
  border-radius: 10px;
  display: flex;
  width: 800px;
  height: auto;
  flex-direction: column;
  padding: 30px 15px;
  font: 28px arial;
  box-shadow: 0px 40px 45px rgba(0, 0, 0, 0.4);
  margin-left: auto;
  margin-right: auto;
  position: relative;

}

div[class*="line"] {
  display: flex;
  justify-content: space-around;
}

div[class*="line"] + div[class*="line"] {
  margin-top: 15px;

}

.line1,
.line3 {
  padding: 0 15px;
}

.line2 {
  padding: 0 30px;
}

div[class*="line"] span {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.7);
  color: white;
  padding: 15px;
  background: #838383;
  border-radius: 12px;
  width: 55px;
  text-align: center;
  font-weight: 600;
}

div[class*="line"] span:hover {
  /*   box-shadow: 0px 2px 10px #040404;*/

  background: #2f3336;
  cursor: pointer;
}

div[class*="line"] span:active {
  transform: scale(0.9);
}

.line3 span:first-of-type {
  width: 35px;
  padding: 10px 15px;
}

.line3 span:last-of-type {
  width: 35px;
  padding: 10px 15px;
}

.line4 span:nth-child(1) {
  width: 55px;
}

.line4 span:nth-child(2) {
  width: 35px;
  padding: 10px 15px;
}

.line4 span:nth-child(3) {
  width: 35px;
  padding: 10px 15px;
}

.line4 span:nth-child(4) {
  width: 255px;
}

.line4 span:last-of-type {
  width: 105px;
}

#arrow,
#backspace,
#smile,
#mic {
  fill: #71d4fe;
  width: 35px;
  height: 35px;

}


.hoverBtn:hover {
  background: #2f3336 !important;

}

.removeChar {
  width: 110px;
  padding: 0;
  padding-top: 11px;
}

@media (max-width:24rem) {
  .base {
    width: 0;
    margin-right: 7.6rem;
    font-size: 0.5rem;
  }
}


@media (max-width:64rem) {
  .base {
    width: 0px;

  }
}


